<template>
  <p class="text-caption text-right graydefault--text mt-4">Los datos marcados con <span class="error--text">*</span> son obligatorios</p>
</template>

<script>
export default {

}
</script>

<style>

</style>